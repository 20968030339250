import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import ReactJsonPretty from 'react-json-pretty';
import { fetchHistory, fetchHistoryForChart } from '../../api/devices';
import { githubLightTheme } from '../../constants/constants';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import ChartComponent from './ChartComponent';
import { useSelector } from 'react-redux';

const DeviceHistoryModal = ({ deviceId, closeModal }) => {
  const controlPanel = useSelector((state) => state.device.schema);
  const chartFields = controlPanel.filter((v) => v.type === 'int');
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(4);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [pageInput, setPageInput] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState({ timestamps: [], fields: {} });

  const handleFieldSelection = (field) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const fetchChart = async () => {
    const data = await fetchHistoryForChart(
      deviceId,
      selectedFields,
      fromDate,
      toDate
    );
    setChartData(data);
    setShowChart(true);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().replace('T', ' ').replace('Z', ' UTC');
  };

  const fetchHistoryData = async (page, from_ts = null, to_ts = null) => {
    try {
      const response = await fetchHistory(
        deviceId,
        page * perPage,
        perPage,
        from_ts,
        to_ts
      );
      setHistory(response.data);
      setTotalPages(Math.ceil(response.total_records / perPage));
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  const handleDateTimeRangeChange = (type, date) => {
    if (type === 'from') {
      setFromDate(date);
    } else if (type === 'to') {
      setToDate(date);
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePerPageChange = (event) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const handlePageInputChange = (event) => {
    setPageInput(event.target.value);
  };

  const handlePageInputSubmit = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    } else {
      setPageInput(currentPage.toString());
    }
  };

  const handlePageInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePageInputSubmit();
    }
  };

  useEffect(() => {
    if (selectedFields.length > 0) {
      fetchChart();
    } else {
      setShowChart(false);
      fetchHistoryData(currentPage - 1, fromDate?.getTime(), toDate?.getTime());
    }
  }, [currentPage, perPage, fromDate, toDate, selectedFields]);

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="min-h-[70dvh] bg-white p-2">
        <h2 className="mb-6 text-2xl font-bold text-gray-800">
          История состояний устройства
        </h2>

        <div className="mb-6">
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-1">
              <label className="mb-2 block text-sm font-medium text-gray-700">
                От:
              </label>
              <DateTimePicker
                onChange={(date) => handleDateTimeRangeChange('from', date)}
                value={fromDate}
                format="dd/MM/yyyy HH:mm:ss"
                disableClock
                clearIcon={null}
                className="w-full rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              />
            </div>
            <div className="flex-1">
              <label className="mb-2 block text-sm font-medium text-gray-700">
                До:
              </label>
              <DateTimePicker
                onChange={(date) => handleDateTimeRangeChange('to', date)}
                value={toDate}
                format="dd/MM/yyyy HH:mm:ss"
                disableClock
                clearIcon={null}
                className="w-full rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              />
            </div>
          </div>
        </div>

        {chartFields && (
          <div className="mb-6">
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Показать график:
            </label>
            <div className="flex flex-wrap gap-3">
              {chartFields.map((field) => (
                <label key={field.name} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={selectedFields.includes(field.name)}
                    onChange={() => handleFieldSelection(field.name)}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <span className="text-sm text-gray-700">{field.name}</span>
                </label>
              ))}
            </div>
          </div>
        )}

        {showChart ? (
          <ChartComponent data={chartData} />
        ) : (
          <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-sm">
            {history.length > 0 ? (
              <>
                <div className="grid min-w-[600px] grid-cols-3 bg-gradient-to-r from-blue-50 to-purple-50 p-3 text-center font-semibold text-gray-700">
                  <span>Время</span>
                  <span>Топик</span>
                  <span>Состояние</span>
                </div>
                {history.map((log, index) => (
                  <div
                    key={index}
                    className="grid min-w-[600px] grid-cols-3 items-center border-b border-gray-100 bg-white p-3 transition-colors hover:bg-gray-50"
                  >
                    <span className="text-sm text-gray-600">
                      {formatTimestamp(log.timestamp_ms)}
                    </span>
                    <span className="text-sm text-gray-800">{log.topic}</span>
                    <ReactJsonPretty
                      id="currentStatus"
                      data={log.msg}
                      theme={githubLightTheme}
                      style={{ fontSize: '12px' }}
                      space="2"
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="p-6 text-center text-gray-500">
                Нет истории состояний
              </div>
            )}
          </div>
        )}

        {!showChart && (
          <div className="mt-6 flex flex-col items-center justify-between gap-4 sm:flex-row">
            <div className="flex items-center gap-2">
              <label className="text-sm text-gray-700">
                Элементов на странице:
              </label>
              <select
                value={perPage}
                onChange={handlePerPageChange}
                className="rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              >
                {[4, 8, 16, 32].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1 || totalPages === 0}
                className="rounded-lg bg-gray-500 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
              >
                &lt;
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
                className="rounded-lg bg-gray-500 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
              >
                &gt;
              </button>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-700">
                  Страница{' '}
                  <input
                    type="number"
                    value={pageInput}
                    onChange={handlePageInputChange}
                    onKeyDown={handlePageInputKeyPress}
                    className="w-16 rounded-lg border border-gray-300 p-1 text-center text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                    min={1}
                    max={totalPages}
                  />{' '}
                  из {totalPages || 1}
                </span>
                <button
                  onClick={handlePageInputSubmit}
                  className="rounded-lg bg-blue-500 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-blue-600"
                >
                  Перейти
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default DeviceHistoryModal;
