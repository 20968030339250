import { createSlice } from '@reduxjs/toolkit';

const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    deviceId: null,
    devices: [],
    triggers: [],
    state: {},
    schema: [],
  },
  reducers: {
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setTriggers(state, action) {
      state.triggers = action.payload;
    },
    setSchema(state, action) {
      state.schema = action.payload;
    },
    setState(state, action) {
      state.state = {
        ...state.state,
        ...action.payload,
      };
    },
    setDeviceId(state, action) {
      state.deviceId = action.payload;
    },
    setStatus(state, action) {
      const { device_id, status } = action.payload;
      const deviceIndex = state.devices.findIndex(
        (device) => device.id === device_id
      );

      if (deviceIndex !== -1) {
        state.devices[deviceIndex].connected = status;
      }
    },
    setBuild(state, action) {
      const { device_id, build_id } = action.payload;
      const deviceIndex = state.devices.findIndex(
        (device) => device.id === device_id
      );

      if (deviceIndex !== -1) {
        state.devices[deviceIndex].current_build = build_id;
      }
    },
    updateDeviceField(state, action) {
      const { deviceId, field, value } = action.payload;
      const deviceIndex = state.devices.findIndex(
        (device) => device.id === deviceId
      );

      if (deviceIndex !== -1) {
        state.devices[deviceIndex][field] = value;
      }
    },
  },
});

export const {
  setDevices,
  setState,
  setTriggers,
  setDeviceId,
  setSchema,
  setStatus,
  setBuild,
  updateDeviceField,
} = deviceSlice.actions;
export default deviceSlice.reducer;
