import React, { useState, useEffect, useCallback } from 'react';
import './Ide.css';
import '../Devices/ESPInstall';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { githubLight } from '@uiw/codemirror-theme-github';
import { useSelector } from 'react-redux';
import { uploadFile } from '../../api/devices';
import throttle from 'lodash.throttle';
import ESPInstall from '../Devices/ESPInstall';

function Ide({ handleFileChange, file, deviceId }) {
  const { firmware } = useSelector((state) => state.firmware);
  const [value, setValue] = useState('');

  const fetchFileContent = async (file) => {
    try {
      const response = await fetch(
        'https://iopp.io/media/build_files/base/base.ino'
      );
      if (!response.ok) throw new Error('Network response was not ok');
      const text = await response.text();
      setValue(text);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFileContent(file);
  }, [file]);

  // Throttled upload function
  const throttledUploadFile = useCallback(
    throttle((content) => {
      uploadFile(content, deviceId)
        .then((response) => {
          console.log('File uploaded successfully:', response);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }, 500),
    [deviceId]
  );

  const onChange = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  const handleSave = () => {
    throttledUploadFile(value);
  };

  // Handle Download as File
  const handleDownload = () => {
    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file ? file.name : 'code.ino';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div id="result" className="flex flex-wrap text-left text-base">
        {firmware?.payload?.status === 'success' && (
          <ESPInstall
            manifest_url={firmware?.payload.firmware_manifest}
          ></ESPInstall>
        )}
        {firmware?.payload?.status && firmware.payload.status !== 'success' && (
          <h2 className="text-lg font-medium text-red-600">
            Firmware compilation error. Please contact support or try again
            later.
          </h2>
        )}
      </div>
      <div className="mt-4 flex items-center space-x-2">
        <div className="w-full items-center rounded-xl border bg-zinc-100 px-4 py-1 text-center hover:bg-zinc-200 md:w-fit">
          <input
            type="file"
            id="firmwareFile"
            className="hidden"
            onChange={handleFileChange}
          />
          <label htmlFor="firmwareFile" className="cursor-pointer">
            Загрузить файл
          </label>
        </div>

        <button
          onClick={handleDownload}
          className="w-full items-center rounded-xl bg-blue-100 px-4 py-1 text-center hover:bg-blue-200 md:w-fit"
        >
          Скачать файл
        </button>
        <button
          onClick={handleSave}
          className="w-full items-center rounded-xl border bg-green-100 px-4 py-1 text-center hover:bg-green-200 md:w-fit"
        >
          Сохранить изменения
        </button>
      </div>

      {/* CodeMirror Editor */}
      <CodeMirror
        theme={githubLight}
        value={value}
        height="100%"
        className="codemirror-editor mt-4 w-full overflow-x-auto border border-gray-600 text-sm"
        extensions={[javascript({ jsx: true })]}
        onChange={onChange} // Ensure onChange is correctly passed
      />
    </>
  );
}

export default Ide;
