import React, { useState, useEffect, useCallback } from 'react';
import ReactJsonPretty from 'react-json-pretty';
import { githubLightTheme } from '../../../constants/constants';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function DeviceStatusSection({ deviceId, updateDeviceState }) {
  const device = useSelector((state) =>
    state.device.devices.find((d) => d.id === deviceId)
  );
  const deviceState = useSelector((state) => state.device.state);
  const controlPanel = useSelector((state) => state.device.schema);

  const [sourceState, setSourceState] = useState(deviceState);
  const [localState, setLocalState] = useState(deviceState);
  const [changedKeys, setChangedKeys] = useState(new Set());
  const [autoSendEnabled, setAutoSendEnabled] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedAutoSend = useCallback(
    debounce((deviceId, changes) => {
      updateDeviceState(deviceId, changes);
      setChangedKeys(new Set());
      setLastUpdateTime(Date.now());
      setIsLoading(true);
    }, 1000),
    [updateDeviceState]
  );

  useEffect(() => {
    if (lastUpdateTime) {
      const timeoutId = setTimeout(() => {
        if (isLoading && Date.now() - lastUpdateTime >= 3000) {
          setLocalState(sourceState);
          setChangedKeys(new Set());
          setIsLoading(false);
          toast.warning('Устройство не отвечает');
        }
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [lastUpdateTime, sourceState]);

  useEffect(() => {
    setSourceState(deviceState);

    const updatedLocalState = { ...deviceState };
    for (const key of changedKeys) {
      updatedLocalState[key] = localState[key];
    }
    setLocalState(updatedLocalState);
    setIsLoading(false);
  }, [deviceState]);

  const isStateChanged = Object.keys(localState).some(
    (key) => localState[key] !== sourceState[key]
  );

  const handleButtonClick = (name) => {
    const currentState = localState[name] || false;
    const updatedState = { ...localState, [name]: !currentState };
    setLocalState(updatedState);
    setChangedKeys((prev) => new Set(prev).add(name));

    if (autoSendEnabled) {
      updateDeviceState(deviceId, { [name]: !currentState });
      setChangedKeys(new Set());
      setLastUpdateTime(Date.now());
      setIsLoading(true);
    }
  };

  const handleInputChange = (name, type, value) => {
    let parsedValue = value;

    // Clean input based on type
    if (type === 'int') {
      parsedValue = value.replace(/[^-\d]/g, '');
      parsedValue = parsedValue.replace(/(?!^-)-/g, '');
    } else if (type === 'float') {
      parsedValue = value.replace(/[^-\d.]/g, '');
      parsedValue = parsedValue.replace(/(?!^-)-/g, '');
      parsedValue = parsedValue.replace(/(\..*)\./g, '$1');
    }

    const updatedState = { ...localState, [name]: parsedValue };
    setLocalState(updatedState);
    setChangedKeys((prev) => new Set(prev).add(name));

    if (autoSendEnabled) {
      debouncedAutoSend(deviceId, { [name]: parsedValue });
    }
  };

  const handleSubmit = () => {
    const changedValues = Object.keys(localState).reduce((acc, key) => {
      if (localState[key] !== sourceState[key]) {
        acc[key] = localState[key];
      }
      return acc;
    }, {});

    updateDeviceState(deviceId, changedValues);
    setChangedKeys(new Set());
    setLastUpdateTime(Date.now());
    setIsLoading(true);
  };

  const revertChange = (key) => {
    const updatedState = { ...localState, [key]: sourceState[key] };
    setLocalState(updatedState);
    setChangedKeys((prev) => {
      const newSet = new Set(prev);
      newSet.delete(key);
      return newSet;
    });
  };

  const changedElements = Object.keys(localState).filter(
    (key) => localState[key] !== sourceState[key]
  );

  const groupedElements = controlPanel.reduce((acc, element) => {
    if (!acc[element.type]) {
      acc[element.type] = [];
    }
    acc[element.type].push(element);
    return acc;
  }, {});

  const typeOrder = ['button', 'float', 'int', 'str'];

  const formatValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'ON' : 'OFF';
    }
    return value;
  };

  return (
    <div className="flex flex-col space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm lg:flex-row lg:space-x-6 lg:space-y-0">
      <div className="w-full lg:w-1/3">
        <div className="h-full rounded-lg border border-gray-200 bg-gray-50 p-4 shadow-[inset_0_-1px_10px_1px_rgba(0,0,0,0.05)]">
          <ReactJsonPretty
            id="currentStatus"
            data={sourceState}
            theme={githubLightTheme}
            style={{ fontSize: '14px' }}
            space="2"
          />
        </div>
      </div>

      <div className="w-full lg:w-2/3">
        <div className="space-y-6">
          {/* Buttons */}
          {!controlPanel.length && (
            <span className="text-gray-500">
              Устройство ни разу не подключалось
            </span>
          )}
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            {groupedElements['button']?.map((element, index) => {
              const isOn = localState[element.name] || false;
              return (
                <button
                  key={index}
                  className={`w-full rounded-lg border border-gray-200 px-4 py-2 text-sm font-medium shadow-[inset_0_-2px_1px_rgba(0,0,0,0.15)] transition-colors duration-75 hover:shadow-none ${
                    isOn
                      ? 'bg-green-100 text-green-900 hover:bg-green-200'
                      : 'bg-red-100 text-red-900 hover:bg-red-200'
                  } `}
                  onClick={() => handleButtonClick(element.name)}
                  disabled={!element.editable || isLoading}
                >
                  {element.name}
                </button>
              );
            })}
          </div>

          {/* Inputs */}
          <div className="space-y-4">
            {typeOrder.map((type) =>
              groupedElements[type]?.map((element, index) => {
                if (type === 'float' || type === 'int' || type === 'str') {
                  return (
                    <div key={index} className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {element.name} ({type}):
                      </label>
                      <input
                        type="text"
                        className={`w-full rounded-lg border border-gray-200 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 ${
                          !element.editable || isLoading
                            ? 'cursor-not-allowed bg-gray-100'
                            : ''
                        }`}
                        value={localState[element.name] || ''}
                        onChange={(e) =>
                          element.editable &&
                          !isLoading &&
                          handleInputChange(
                            element.name,
                            element.type,
                            e.target.value
                          )
                        }
                        disabled={!element.editable || isLoading}
                      />
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>

          {/* List of changed and uncommitted elements */}
          {changedElements.length > 0 && !autoSendEnabled && (
            <div className="mt-4">
              <h3 className="mb-3 text-sm font-medium text-gray-700">
                Измененные значения:
              </h3>
              <div className="grid grid-cols-1 gap-3">
                {changedElements.map((key) => (
                  <div
                    key={key}
                    className="cursor-pointer rounded-lg border border-gray-200 p-3 transition-colors hover:bg-red-50"
                    onClick={() => !isLoading && revertChange(key)}
                  >
                    <div className="grid grid-cols-2 gap-2">
                      <div className="truncate text-sm font-medium text-blue-700">
                        {key}
                      </div>
                      <div className="truncate text-sm text-gray-900">
                        {formatValue(deviceState[key])} →{' '}
                        {formatValue(localState[key])}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Submit Changes Button and Auto-Send Toggle */}
          <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
            <button
              className={`w-full rounded-sm px-4 py-2 font-medium transition-colors ${
                isStateChanged && !autoSendEnabled && !isLoading
                  ? 'bg-green-200 text-emerald-800 hover:bg-green-300'
                  : 'cursor-not-allowed bg-gray-200 text-gray-400'
              }`}
              onClick={handleSubmit}
              disabled={!isStateChanged || autoSendEnabled || isLoading}
            >
              {isLoading ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-400 border-t-transparent"></div>
                  <span>Отправка...</span>
                </div>
              ) : (
                'Отправить изменения на устройство'
              )}
            </button>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-700">Автоотправка</span>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={autoSendEnabled}
                  onChange={() => setAutoSendEnabled(!autoSendEnabled)}
                  disabled={isLoading}
                />
                <div
                  className={`h-6 w-11 rounded-full bg-gray-200 transition-colors ${
                    autoSendEnabled ? 'bg-green-500' : 'bg-gray-400'
                  }`}
                >
                  <div
                    className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform ${
                      autoSendEnabled ? 'translate-x-5' : ''
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceStatusSection;
