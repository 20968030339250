import { Line } from 'react-chartjs-2';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const generateRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
};

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
    },
  },
};

const ChartComponent = ({ data }) => {
  const chartData = {
    labels: data.timestamps.map((ts) => new Date(ts).toLocaleString()),
    datasets: Object.keys(data.fields).map((field) => ({
      label: field,
      data: data.fields[field],
      borderColor: generateRandomColor(),
      borderWidth: 2,
      fill: false,
      tension: 0.1,
    })),
  };

  return (
    <div style={{ width: '80%', margin: '0 auto' }}>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default ChartComponent;
