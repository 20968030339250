import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './ControlPage.css';
import Devices from '../../components/Devices/Devices';
import Layout from '../../components/Layout';
import Header from '../../components/Header/Header';
import Questions from '../../components/Questions/Questions';
import Modals from './Modals';
import useDeviceManagement from '../../hooks/useDeviceManagement';
import useUserData from '../../hooks/useUserData';
import useModalManagement from '../../hooks/useModalManagement';

const faqElements = [
  {
    buttonText: 'Как добавить новое устройство?',
    text1:
      'заполните название, логин и пароль от локальной сети Wi-Fi. После нажатия на кнопку начнется процесс компиляции. По окончанию компиляции подключите устройство по USB и нажмите на кнопку "Установить прошивку".',
    text2:
      'также вы можете самостоятельно прошить устройство, для этого вам нужно создать устройство и скопировать UUID и пароль в окне устройства. Эти данные нужно подставить в базовую прошивку.',
  },
  {
    buttonText: 'Как обновить прошивку устройства?',
    text1:
      'чтобы обновить прошивку, мы можете модифицировать базовую прошивку устройства для ваших целей. Если устройство подключено к платформе через базовую прошивку, откройте окно устройства и выберите файл .ino',
  },
];

const FAQSection = ({ faqElements }) => {
  const [openIndex, setOpenIndex] = React.useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="mb-5 space-y-2 px-5">
      {faqElements.map((item, index) => (
        <Questions
          key={index}
          isOpen={openIndex === index}
          isClosingInstantly={openIndex !== null && openIndex !== index}
          onToggle={() => handleToggle(index)}
          item={item}
        />
      ))}
    </div>
  );
};

const Welcome = () => (
  <div className="flex justify-center pl-[3em] py-5">
    <ol className="w-full">
      <li className="bg-zinc-100 w-full">Добавьте устройство</li>
      <li className="bg-zinc-100 w-full">
        Откройте устройство и перейдите в раздел &quot;Сборки&quot;
      </li>
      <li className="bg-zinc-100 w-full">
        При готовности сборки, подключите ESP8266 по USB и нажмите на кнопку
        &quot;Установить через USB&quot;
      </li>
    </ol>
  </div>
);

function ControlPage() {
  const { devices, isLoading } = useDeviceManagement();
  const { userData, logout } = useUserData();
  const { handleOpenModal } = useModalManagement();
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('access_token');
    if (!user) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Layout>
      <Header
        userData={userData}
        onAddDevice={() => handleOpenModal('deviceCreationModal')}
        onLogout={logout}
      />
      <div className="flex flex-col gap-8">
        <div className="flex-1">
          <div id="mainSection">
            {isLoading || devices?.length > 0 ? (
              <Devices devices={devices} userBalance={userData?.balance || 0} />
            ) : (
              <div className="">
                <Welcome />
                {/* <FAQSection faqElements={faqElements} /> */}
              </div>
            )}
          </div>
        </div>
        <Modals />
      </div>
    </Layout>
  );
}

export default ControlPage;
