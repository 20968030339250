import { createSlice } from '@reduxjs/toolkit';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    loginModal: false,
    deviceModal: false,
    deviceCreationModal: false,
    firmwareInfoModal: false,
    buildModal: false,
    eventLogModal: false,
    deviceHistoryModal: false,
  },
  reducers: {
    openModal: (state, action) => {
      const modalName = action.payload;
      return {
        ...state,
        [modalName]: true,
      };
    },
    closeModal: (state, action) => {
      const modalName = action.payload;
      return {
        ...state,
        [modalName]: false,
      };
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;
export default modalsSlice.reducer;
