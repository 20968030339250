import React from 'react';
import './Footer.css';

function Footer() {
  const legalNodes = [
    {
      href: 'docs/privacy.pdf',
      text: 'Обработка персональных данных',
    },
    {
      href: 'docs/offer.pdf',
      text: 'Договор оферты',
    },
    {
      href: 'docs/payments.pdf',
      text: 'Политика безопасности платежей',
    },
    {
      href: 'docs/payment_and_refund.pdf',
      text: 'Платежи и тарифы',
    },
    {
      href: 'docs/agreement.pdf',
      text: 'Пользовательское соглашение',
    },
    {
      href: 'docs/contacts.pdf',
      text: 'Реквизиты и контакты',
    },
  ];

  const links = [
    {
      href: 'docs',
      text: 'Инструкция по использованию платформы',
    },
    // {
    //   href: 'docs',
    //   text: 'Документация API',
    // },
    // {
    //   href: 'health',
    //   text: 'Статус платформы',
    // },
  ];
  return (
    <footer className="w-full border-t border-gray-300 bg-gradient-to-t from-[#9cfff222] to-[#9cfff20a]">
      <div className="flex flex-col justify-between gap-4 px-5 py-7 lg:flex-row lg:gap-[10px] lg:px-[100px] lg:py-10">
        <div className="flex flex-col gap-3">
          <div className="text-lg font-semibold text-gray-800">
            Документация
          </div>
          <div className="flex flex-col gap-1">
            {legalNodes.map((node, index) => {
              return (
                <a
                  key={index}
                  href={node.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-gray-600 transition-all hover:text-blue-500"
                >
                  <span>
                    <span style={{ textDecoration: 'underline' }}>
                      {node.text[0]}
                    </span>
                    {node.text.slice(1)}
                  </span>
                </a>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-lg font-semibold text-gray-800">Ссылки</div>
          <div className="flex flex-col gap-1">
            {links.map((node, index) => (
              <a
                key={index}
                href={node.href}
                target="_blank"
                rel="noreferrer"
                className="text-sm text-gray-600 transition-all hover:text-blue-500"
              >
                <span>
                  <span style={{ textDecoration: 'underline' }}>
                    {node.text[0]}
                  </span>
                  {node.text.slice(1)}
                </span>
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3 lg:max-w-[40%]">
          <div className="text-lg font-semibold text-gray-800">Контакты</div>
          <div className="flex flex-col gap-1">
            <div className="text-sm text-gray-600">
              Юридический адрес: 445044, Россия, Самарская область, г. Тольятти,
              ул. Автостроителей, д. 36, кв. 107
            </div>
            <div className="text-sm text-gray-600">
              <a className="text-inherit" href="mailto:iopp.io@yandex.ru">
                iopp.io@yandex.ru
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full shrink-0 flex-col items-center gap-2 bg-gray-100 p-5 lg:h-[30px] lg:flex-row lg:gap-5 lg:px-[100px]">
        <div className="text-sm text-gray-600">
          © 2025 <span className="font-bold">iopp</span>
        </div>
        <div className="text-sm text-gray-500">
          ООО {'"Альфа Лайн"'}, ИНН 6321409124, КПП 632101001
        </div>
      </div>
    </footer>
  );
}

export default Footer;
