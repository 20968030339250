import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import ESPInstall from '../Devices/ESPInstall';
import { useDispatch, useSelector } from 'react-redux';
import { startOTA } from '../../api/devices';
import { openModal } from '../../redux/modals';

function FirmwareInfoModal({ deviceId, firmwareInfo, closeFirmwareInfoModal }) {
  const dispatch = useDispatch();
  const { devices } = useSelector((state) => state.device);
  const [expandedBuilds, setExpandedBuilds] = useState({});
  const device = devices.find((d) => d.id === deviceId);
  const toggleBuild = (firmwareIndex, buildIndex) => {
    setExpandedBuilds((prev) => {
      const key = `${firmwareIndex}-${buildIndex}`;
      return { ...prev, [key]: !prev[key] };
    });
  };

  const ota = (firmwareId) => {
    startOTA(firmwareId);
  };

  const openBuildModal = () => {
    dispatch(openModal('buildModal'));
  };

  return (
    <ModalLayout closeModal={closeFirmwareInfoModal}>
      <div className="flex items-center justify-between border-b border-gray-200 p-4">
        <h2 className="text-xl font-semibold text-gray-800">
          Прошивки устройства
        </h2>
      </div>
      <div className="p-4">
        {!firmwareInfo.length && (
          <h3>Для создания прошивки откройте окно &quot;Компиляция&quot;</h3>
        )}
        {firmwareInfo?.map((firmware, firmwareIndex) => (
          <div
            key={firmware.id}
            className="mb-4 rounded-lg border-2 border-gray-300 p-4"
          >
            <div className="flex items-center justify-between">
              <h3 className="text-md font-semibold text-gray-700">
                Версия прошивки: {firmware.version}
              </h3>
            </div>
            <div className="mt-2">
              {firmware.builds.map((build, buildIndex) => {
                const key = `${firmwareIndex}-${buildIndex}`;
                const isExpanded = expandedBuilds[key];

                return (
                  <div key={buildIndex} className="mb-2">
                    <button
                      onClick={() => toggleBuild(firmwareIndex, buildIndex)}
                      className={`w-full p-2 text-left ${device.current_build === build.id ? 'bg-green-100 hover:bg-green-200' : 'bg-gray-100 hover:bg-gray-200'} ${device.current_build === null && 'animate-pulse'} rounded-lg transition-colors duration-200`}
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-700">
                          Сборка от {build.created_at}
                        </span>
                        <span className="text-sm text-gray-400">
                          {build.id}
                        </span>
                        <span className="text-sm text-gray-500">
                          {isExpanded ? '▲' : '▼'}
                        </span>
                      </div>
                    </button>
                    {isExpanded && (
                      <div className="mt-2 flex justify-between rounded-lg bg-gray-50 p-3">
                        <div className="space-y-1 text-sm text-gray-700">
                          <p>Статус: {build.status}</p>
                          <p>
                            Лог: {build.status === 'failed' ? <br /> : ' '}
                            {build.result_log &&
                              build.result_log
                                .split('\n')
                                .map(function (item, idx) {
                                  return (
                                    <span key={idx}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })}
                          </p>
                        </div>
                        {build.status === 'completed' && (
                          <div className="mt-3 flex gap-4">
                            <ESPInstall manifest_url={build.manifest} />
                            <button
                              className="btn-primary"
                              onClick={() => ota(build.id)}
                            >
                              Запустить OTA
                            </button>
                          </div>
                        )}
                        {build.status === 'failed' && (
                          <button
                            className="btn-primary"
                            onClick={() => openBuildModal()}
                          >
                            Перезапустить сборку
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </ModalLayout>
  );
}

export default FirmwareInfoModal;
