import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Lending from './pages/Lending/Lending';
import { Routes, Route, Navigate } from 'react-router-dom';
import ControlPage from './pages/ControlPage/ControlPage';
import { useDispatch, useSelector } from 'react-redux';
import { addFirmware } from './redux/firmware';
import { setSchema, setState, setStatus, setBuild } from './redux/device';
import { WS_URL } from './constants/constants';
import { toast } from 'react-toastify';
import { addPayment } from './redux/user';
import PageNotFound from './components/NotFound';

function App() {
  const websocket_channel = localStorage.getItem('websocket_channel');
  const dispatch = useDispatch();
  const deviceId = useSelector((state) => state.device.deviceId);
  const activeView = useSelector((state) => state.user.activeView);
  const deviceIdRef = useRef(deviceId);
  const activeViewRef = useRef(activeView);

  useEffect(() => {
    deviceIdRef.current = deviceId;
  }, [deviceId]);

  useEffect(() => {
    activeViewRef.current = activeView;
  }, [activeView]);

  async function connectWebSocket(channel) {
    let socket = new WebSocket(WS_URL);
    let retryCount = 0;
    const maxRetries = 5;

    socket.onopen = () => {
      console.log('WebSocket connection opened.');
      retryCount = 0;
      socket.send(
        JSON.stringify({ command: 'join', args: { channel: channel } })
      );
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message:', data);

      if (data.type === 'firmware') {
        dispatch(addFirmware(data.payload));
      } else if (data.type === 'toast') {
        if (data?.error) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
      } else if (data.type === 'state') {
        if (
          data.data?.device_id &&
          data.data.device_id === deviceIdRef.current
        ) {
          dispatch(setState(data.data.state));
          dispatch(setSchema(data.data.schema));
        }
      } else if (data.type === 'status') {
        if (data.data?.device_id) {
          dispatch(setStatus(data.data));
        }
      } else if (data.type === 'build') {
        if (data.data?.device_id) {
          dispatch(setBuild(data.data));
        }
      } else if (data.type === 'payment') {
        if (activeViewRef.current === 'credits') {
          dispatch(addPayment(data.data));
        }
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
      if (retryCount < maxRetries) {
        const retryDelay = Math.min(100 * Math.pow(2, retryCount), 30000);
        retryCount++;
        setTimeout(() => connectWebSocket(channel), retryDelay);
      } else {
        console.log(
          'Error reconnecting to WebSocket. Please contact support or try again later.'
        );
      }
    };
  }

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('access_token');
    if (user) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (websocket_channel) {
      connectWebSocket(websocket_channel);
    }
  }, [websocket_channel]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? <Navigate to="/control" replace /> : <Lending />
        }
      />
      <Route
        path="/control"
        element={
          isAuthenticated ? <ControlPage /> : <Navigate to="/" replace />
        }
      />
      <Route path='*' element={<Navigate to="/404" replace />} status={404} />
      <Route path='/404' element={<PageNotFound />} status={404} />
    </Routes>
  );
}

export default App;
