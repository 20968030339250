import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import { BOT_NAME } from '../../constants/constants';
import { pollAccessKey } from '../../api/login';
import { useDispatch } from 'react-redux';
import useDeviceManagement from '../../hooks/useDeviceManagement';
import { toast } from 'react-toastify';

function LoginModal({ closeModal, token }) {
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Скопировано в буфер обмена!');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };
  return (
    <ModalLayout closeModal={closeModal} className="!min-w-0 !min-h-0">
      <div className="p-5 space-y-5 text-sm lg:text-lg">
        <h1>
          Для авторизации напишите Telegram боту{' '}
          <span
            className="text-blue-700 text-xl cursor-pointer"
            onClick={() => handleCopyToClipboard(`@${BOT_NAME}`)}
          >
            @{BOT_NAME}
          </span>{' '}
          сообщение:
        </h1>
        <div
          onClick={() => handleCopyToClipboard(`/start ${token}`)}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm bg-gray-100 cursor-pointer overflow-y-hidden text-nowrap "
        >
          <span className="text-blue-700">/start</span> {token}
        </div>
        <span className="text-center text-sm text-gray-500">
          Нажмите для копирования в буфер обмена
        </span>
      </div>
    </ModalLayout>
  );
}

export default LoginModal;
