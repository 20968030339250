import { useDispatch } from 'react-redux';
import { API_URL } from '../constants/constants';
// import { connectWebSocket } from "./websoket";
import { BOT_NAME } from '../constants/constants';
import { openModal } from '../redux/modals';

export async function oauth(openLogin, setToken) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (
    urlParams.get('state') !== null &&
    urlParams.get('redirect_uri') !== null &&
    urlParams.get('client_id') !== null
  ) {
    if (!localStorage.getItem('access_token')) {
      await handleAuthButtonClick(openLogin, setToken, false);
    }
    const response = await fetch(`${API_URL}/v1.0/auth?` + urlParams, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('access_token'),
      },
    });
    const data = await response.json();
    if (data?.['redirect_uri']) {
      window.location.href = data['redirect_uri'];
    }
  }
}

export async function pollAccessKey(sessionId) {
  let response = {}
  try {
    let i = 0;
    while (i < 10) {
      i++;
      response = await fetch(
        `${API_URL}/auth/login_session/${sessionId}`,
        { credentials: 'same-origin' }
      );
      
      try {
        const result = await response.json();
        localStorage?.setItem('access_token', result.access_token);
        localStorage?.setItem('refresh_token', result.refresh_token);
        if (result.websocket_channel) {
          return result.websocket_channel;
        } else if (result.status === 'pending') {
          await new Promise((resolve) => setTimeout(resolve, 3000));
        } else {
          return null;
        }
      } catch (error) {
        continue
      }
    }
  } catch (error) {
    console.error('Error polling session:', error);
    return null;
  }
}

export async function handleAuthButtonClick(openLogin, setToken, openTelegram = true) {
  try {
    const loginSessionResponse = await fetch(`${API_URL}/auth/login_session`);
    const loginSession = await loginSessionResponse.json();
    setToken(loginSession.session_id)
    if (loginSession.session_id) {
      if (openTelegram) {
        window.open(
          `https://t.me/${BOT_NAME}?start=${loginSession.session_id}`,
          '_blank'
        );
      }
      
      openLogin()
      const websocket_channel = await pollAccessKey(loginSession.session_id);
      // await oauth();
      if (websocket_channel) {
        localStorage.setItem('websocket_channel', websocket_channel);
        window.location.replace('/control');
        // connectWebSocket(websocket_channel);
      }
    }
  } catch (error) {
    console.error('Error during Telegram login:', error);
  }
}
