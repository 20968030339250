import React, { useEffect } from 'react';
import './Lending.css';
import { handleAuthButtonClick, oauth } from '../../api/login';
import Layout from '../../components/Layout';
import Logo from '../../components/Logo';
import { accessToken } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../../components/Modals/LoginModal';
import useModalManagement from '../../hooks/useModalManagement';
import { openModal } from '../../redux/modals';
import { useDispatch, useSelector } from 'react-redux';
import useUserData from '../../hooks/useUserData';
import { setToken } from '../../redux/user';

function Lending() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const { modals, handleCloseModal } = useModalManagement();
  const faqItems = [
    {
      answer: `заполните название, логин и пароль от локальной сети Wi-Fi. После нажатия на кнопку начнется процесс компиляции. По окончанию компиляции подключите устройство по USB и нажмите на кнопку "Установить прошивку".`,
      title: 'Как добавить новое устройство?',
    },
    {
      answer: `чтобы обновить прошивку, мы можете модифицировать базовую прошивку устройства для ваших целей. Если устройство подключено к платформе через базовую прошивку, откройте окно устройства и выберите файл .ino`,
      title: 'Как обновить прошивку устройства?',
    },
  ];

  const openLogin = () => dispatch(openModal('loginModal'));
  const updateToken = (t) => dispatch(setToken(t));

  useEffect(() => {
    oauth(openLogin, updateToken);
  }, []);

  return (
    <Layout header="false">
      <div
        className="min-h-vh flex min-h-[20dvh] flex-col items-center justify-center text-center"
        id="logo-container"
      >
        <div id="logo-landing" className="rounded">
          <div>
            <Logo />
            <div className="links-container m-5 text-[24px]">
              <a href="#about">about</a>
              <span className="separator">|</span>
              <a href="#footer">docs</a>
              <span className="separator">|</span>
              <a href="#faq">FAQ</a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center pt-5">
        <button
          onClick={() => handleAuthButtonClick(openLogin, updateToken)}
          id="login-button"
          className="login-button w-[200px] border border-black bg-white text-[2em] hover:text-green-500 lg:w-[500px]"
        >
          log in
        </button>
      </div>

      <div className="p-5">
        <h2 id="about">iopp.io</h2>
        <p>
          универсальный облачный сервис, который позволяет легко подключать,
          управлять и мониторить устройства на базе ESP (ESP8266, ESP32) в
          рамках ваших DIY проектов. Сервис предлагает широкий спектр функций,
          делая управление умным домом или другими проектами интуитивно понятным
          и удобным
        </p>

        <div className="features mt-5">
          <div className="feature">
            <h3 className="pb-3">простое подключение</h3>
            <ul>
              <li>поддержка популярных платформ ESP (ESP8266, ESP32)</li>
              <li>
                интуитивный интерфейс для настройки подключения устройств к
                облаку
              </li>
              <li>прошивка прямо через браузер или OTA</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">управление и мониторинг</h3>
            <ul>
              <li>
                удаленное управление устройствами через веб-интерфейс или
                мобильное приложение
              </li>
              <li>визуализация данных с датчиков в реальном времени</li>
              <li>
                создание сценариев для автоматизации управления устройствами
              </li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">аналитика и статистика</h3>
            <ul>
              <li>сбор и хранение данных с устройств в облаке</li>
              <li>генерация отчетов и графиков для анализа</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">интеграции</h3>
            <ul>
              <li>
                поддержка{' '}
                <span className="yandex-gradient text-[1.1rem]">
                  Яндекс Алисы
                </span>{' '}
                для управления устройствами голосом
              </li>
              <li>
                возможность создания собственных команд для голосового
                управления
              </li>
              <li>подключение к мобильному приложению Умный дом</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">триггеры и оповещения</h3>
            <ul>
              <li>
                <span>настройка триггеров</span> на основе данных с датчиков или
                других событий
              </li>
              <li>отправка оповещений на электронную почту, Telegram</li>
            </ul>
          </div>

          <div className="feature">
            <h3 className="pb-3">Среда разработки</h3>
            <ul>
              <li>
                IDE на платформе с возможностью скомпилировать и загрузить новую
                прошивку на устройство
              </li>
            </ul>
          </div>
        </div>
        <hr />

        <p className="advantages border">
          iopp.io - это идеальное решение для тех, кто хочет создать умный дом
          или другой проект на базе ESP устройств, не тратя время на сложную
          настройку или программирование
        </p>
        <hr />

        <h2 id="faq">FAQ</h2>
        {faqItems.map((faq) => (
          <div key={faq.id}>
            <div className="mt-5 pb-5">{faq.title}</div>
            <div className="border bg-slate-100 p-2">{faq.answer}</div>
          </div>
        ))}
      </div>
      {modals.loginModal && (
        <LoginModal
          closeModal={() => handleCloseModal('loginModal')}
          token={token}
        />
      )}
    </Layout>
  );
}

export default Lending;
