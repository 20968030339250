import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/modals';
import { deviceRunBuild } from '../../api/devices';

function FirmwareBuildModal({ deviceId, previousLibraries, closeModal }) {
  const dispatch = useDispatch();
  const [libraries, setLibraries] = useState(previousLibraries || '');
  const [error, setError] = useState('');

  const repoPattern =
    /^https:\/\/github\.com\/[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+\.git(?:#[a-zA-Z0-9_.-]+)?$/;

  const handleInputChange = (event) => {
    setLibraries(event.target.value);
    setError('');
  };

  const runBuild = () => {
    deviceRunBuild(deviceId, libraries);
  };

  const validateLibraries = (input) => {
    if (!input) {
      return true;
    }

    const repoStrings = input.split(/\s+/);

    for (const repo of repoStrings) {
      if (repo && !repoPattern.test(repo)) {
        setError(
          `Invalid repository format: ${repo}. Expected format: https://github.com/owner/repo.git#directory`
        );
        return false;
      }
    }

    return true;
  };

  const handleRunBuild = () => {
    if (validateLibraries(libraries)) {
      runBuild(libraries);
      closeModal();
      dispatch(openModal('firmwareInfoModal'));
    }
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="rounded-lg bg-white p-6 shadow-lg">
        {/* Code Block */}
        <div className="mb-6 rounded-lg border border-gray-200 bg-gray-50 p-4">
          <pre className="whitespace-pre-wrap break-words font-mono text-sm text-gray-700">
            <code>
              <span className="text-blue-600">arduino-cli compile</span> \<br />
              &nbsp;&nbsp;--fqbn esp8266:esp8266:nodemcu \<br />
              &nbsp;&nbsp;--library /build/components/ArduinoJson/src \<br />
              &nbsp;&nbsp;--library /build/components/pubsubclient/src \<br />
              &nbsp;&nbsp;
              <span className="text-gray-400">--library ...</span>
            </code>
          </pre>
        </div>

        {/* Libraries Input */}
        <div className="mb-6">
          <textarea
            value={libraries}
            onChange={handleInputChange}
            placeholder="Например: https://github.com/Arduino-IRremote/Arduino-IRremote.git#src&#10;Где #src - путь до исходных файлов библиотеки"
            className="w-full resize-y rounded-lg border border-gray-300 p-3 transition-all duration-200 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={4}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-4 rounded-lg border border-red-200 bg-red-50 p-3">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        {/* Run Build Button */}
        <button
          onClick={handleRunBuild}
          className="w-full rounded-sm border border-gray-300 bg-gray-200 px-4 py-2.5 font-medium text-black transition-all duration-100 hover:bg-gray-300"
        >
          Запустить сборку
        </button>
      </div>
    </ModalLayout>
  );
}

export default FirmwareBuildModal;
