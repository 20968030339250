import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../../redux/modals';
import { updateDeviceField } from '../../redux/device';
import { uploadFirmware } from '../../api/uploadFirmware';
import ModalLayout from './ModalLayout';
import {
  updateDevice,
  updateDeviceState as updateState,
  deviceRunBuild,
} from '../../api/devices';
import Ide from '../Ide/Ide';
import DeviceInfoTable from './DeviceModal/DeviceInfoTable';
import DeviceStatusSection from './DeviceModal/DeviceStatusSection';
import DeviceActions from './DeviceModal/DeviceActions';
import DeviceYandex from './DeviceModal/DeviceYandex';
import { throttle } from 'lodash';
import { toast } from 'react-toastify';
import FirmwareBuildModal from './FirmwareBuildModal';
import DeviceTrigger from './DeviceModal/DeviceTrigger';
import { fetchTriggers } from '../../api/trigger';

const DeviceFormFields = ({
  name,
  group,
  wifi_ssid,
  wifi_password,
  handleNameChange,
  handleGroupChange,
  handleSsidChange,
  handlePasswordChange,
  getBorderColor,
}) => (
  <div className="space-y-4">
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Название:
        </label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={handleNameChange}
          className={`mt-1 block w-full rounded-md border p-2 font-medium text-gray-700 ${getBorderColor('name')}`}
        />
      </div>
      <div>
        <label
          htmlFor="wifi_ssid"
          className="block text-sm font-medium text-gray-700"
        >
          Wi-Fi SSID:
        </label>
        <input
          id="wifi_ssid"
          type="text"
          value={wifi_ssid}
          onChange={handleSsidChange}
          className={`mt-1 block w-full rounded-md border p-2 font-medium text-gray-700 ${getBorderColor('wifi_ssid')}`}
        />
      </div>
      <div>
        <label
          htmlFor="group"
          className="block text-sm font-medium text-gray-700"
        >
          Группа:
        </label>
        <input
          id="group"
          type="text"
          value={group}
          onChange={handleGroupChange}
          className={`mt-1 block w-full rounded-md border p-2 font-medium text-gray-700 ${getBorderColor('group')}`}
        />
      </div>

      <div>
        <label
          htmlFor="wifi_password"
          className="block text-sm font-medium text-gray-700"
        >
          Wi-Fi пароль:
        </label>
        <input
          id="wifi_password"
          type="text"
          value={wifi_password}
          onChange={handlePasswordChange}
          className={`mt-1 block w-full rounded-md border p-2 font-medium text-gray-700 ${getBorderColor('wifi_password')}`}
        />
      </div>
    </div>
  </div>
);

function DeviceModal({
  closeDeviceModal,
  handleFileChange,
  file,
  removeDevice,
  firmwareInfo,
}) {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.device.devices);
  const deviceId = useSelector((state) => state.device.deviceId);
  const deviceState = useSelector((state) => state.device.state);
  const triggers = useSelector((state) => state.device.triggers);
  const device = devices.find((d) => d.id === deviceId);

  const openEventLogModal = () => dispatch(openModal('eventLogModal'));
  const openFirmwareInfoModal = () => dispatch(openModal('firmwareInfoModal'));
  const openBuildModal = () => dispatch(openModal('buildModal'));
  const openDeviceHistoryModal = () =>
    dispatch(openModal('deviceHistoryModal'));

  const [showYandex, setShowYandex] = useState(false);
  const [showTrigger, setShowTrigger] = useState(false);
  const [showIde, setShowIde] = useState(false);
  const [name, setName] = useState(device?.name || '');
  const [group, setGroup] = useState(device?.group || '');
  const [wifi_ssid, setSsid] = useState(device?.wifi_ssid || '');
  const [wifi_password, setPassword] = useState(device?.wifi_password || '');

  const [fieldStatus, setFieldStatus] = useState({
    name: { isSaving: false, hasError: false },
    group: { isSaving: false, hasError: false },
    wifi_ssid: { isSaving: false, hasError: false },
    wifi_password: { isSaving: false, hasError: false },
  });

  const validInputRegex = /^[a-zA-Z0-9_]*$/;

  useEffect(() => {
    if (file !== null && deviceId !== undefined) {
      uploadFirmware(deviceId, file);
    }
  }, [file]);

  const toggleIde = () => setShowIde((prev) => !prev);

  const validateInput = (value) => validInputRegex.test(value);

  const throttledUpdate = useCallback(
    throttle(async (deviceId, data, fieldName) => {
      try {
        if (
          (fieldName === 'wifi_ssid' || fieldName === 'wifi_password') &&
          !validateInput(data[fieldName])
        ) {
          throw new Error('Поле не должно содержать спец. символов');
        }

        await updateDevice(deviceId, data);

        dispatch(
          updateDeviceField({
            deviceId,
            field: fieldName,
            value: data[fieldName],
          })
        );
      } catch (error) {
        toast.warning(error.message || 'Ошибка при обновлении');
        setFieldStatus((prev) => ({
          ...prev,
          [fieldName]: { isSaving: false, hasError: true },
        }));
      } finally {
        setFieldStatus((prev) => ({
          ...prev,
          [fieldName]: { isSaving: false, hasError: prev[fieldName].hasError },
        }));
      }
    }, 2000),
    [dispatch]
  );

  const handleNameChange = (event) => {
    setFieldStatus((prev) => ({
      ...prev,
      name: { isSaving: true, hasError: false },
    }));
    const newName = event.target.value;
    setName(newName);
    throttledUpdate(deviceId, { name: newName }, 'name');
  };

  const handleGroupChange = (event) => {
    setFieldStatus((prev) => ({
      ...prev,
      group: { isSaving: true, hasError: false },
    }));
    const newGroup = event.target.value;
    setGroup(newGroup);
    throttledUpdate(deviceId, { group: newGroup }, 'group');
  };

  const handleSsidChange = (event) => {
    const newSsid = event.target.value;
    setSsid(newSsid);

    if (!validateInput(newSsid)) {
      setFieldStatus((prev) => ({
        ...prev,
        wifi_ssid: { isSaving: false, hasError: true },
      }));
      toast.warning('SSID не должен содержать спец. символов');
      return;
    }

    setFieldStatus((prev) => ({
      ...prev,
      wifi_ssid: { isSaving: true, hasError: false },
    }));
    throttledUpdate(deviceId, { wifi_ssid: newSsid }, 'wifi_ssid');
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    if (!validateInput(newPassword)) {
      setFieldStatus((prev) => ({
        ...prev,
        wifi_password: { isSaving: false, hasError: true },
      }));
      toast.warning('Пароль не должен содержать спец. символов');
      return;
    }

    setFieldStatus((prev) => ({
      ...prev,
      wifi_password: { isSaving: true, hasError: false },
    }));
    throttledUpdate(deviceId, { wifi_password: newPassword }, 'wifi_password');
  };

  const getBorderColor = (fieldName) => {
    const { isSaving, hasError } = fieldStatus[fieldName];
    if (isSaving) return 'border-yellow-500';
    if (hasError) return 'border-red-500';
    return 'border-gray-300';
  };

  const updateDeviceState = (deviceId, changedValues) => {
    updateState(deviceId, changedValues);
  };

  if (!device) {
    return null;
  }

  return (
    <>
      <ModalLayout closeModal={closeDeviceModal}>
        {/* <img
          className="absolute top-0 right-[120px] rotate-and-clip hidden md:block"
          width="100"
          src="/esp8266.png"
          alt="image"
        /> */}
        <div className="space-y-6">
          <DeviceFormFields
            name={name}
            group={group}
            wifi_ssid={wifi_ssid}
            wifi_password={wifi_password}
            handleNameChange={handleNameChange}
            handleGroupChange={handleGroupChange}
            handleSsidChange={handleSsidChange}
            handlePasswordChange={handlePasswordChange}
            getBorderColor={getBorderColor}
          />
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            <DeviceInfoTable device={device} />
            <div className="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="border-b border-gray-200 bg-gray-50 p-3 font-semibold text-gray-700">
                      Статус устройства
                    </td>
                    <td className="border-b border-gray-200 p-3">
                      {!device.connected ? (
                        <span className="font-semibold text-gray-500">
                          Не подключено
                        </span>
                      ) : (
                        <span className="font-semibold text-green-600">
                          Подключено
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-gray-50 p-3 font-semibold text-gray-700">
                      Последнее время соединения
                    </td>
                    <td className="p-3">{device.last_online}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <DeviceStatusSection
            deviceId={deviceId}
            updateDeviceState={updateDeviceState}
          />
          <DeviceActions
            removeDevice={removeDevice}
            isFresh={Object.keys(deviceState).length === 0}
            openYandex={() => setShowYandex(true)}
            openTrigger={() => setShowTrigger(true)}
            toggleIde={toggleIde}
            openEventLogModal={openEventLogModal}
            openBuildModal={openBuildModal}
            openFirmwareInfoModal={openFirmwareInfoModal}
            openDeviceHistoryModal={openDeviceHistoryModal}
            deviceId={deviceId}
          />
        </div>
        <div
          id="deviceModalCode"
          className={`transition-all duration-500 ease-in-out ${
            showIde ? 'opacity-100' : 'hidden h-[0] opacity-0'
          }`}
        >
          <Ide
            handleFileChange={handleFileChange}
            removeDevice={removeDevice}
            file={firmwareInfo?.[0]?.file}
            deviceId={deviceId}
          />
        </div>
      </ModalLayout>
      {showYandex && <DeviceYandex closeModal={() => setShowYandex(false)} />}

      {showTrigger && (
        <DeviceTrigger
          deviceId={deviceId}
          triggers={triggers}
          closeModal={() => setShowTrigger(false)}
        />
      )}
    </>
  );
}

export default DeviceModal;
