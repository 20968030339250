import React, { useEffect, useRef } from 'react';
import { useModal } from '../ModalContext';
import './ModalLayout.css';

function ModalLayout({ closeModal, children, className }) {
  const modalRef = useRef(null);
  const { pushModal, popModal, getTopModal } = useModal();

  useEffect(() => {
    pushModal(modalRef);

    return () => {
      popModal();
    };
  }, [pushModal, popModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const topModalRef = getTopModal();

      const isEwtInstallDialog = event.target.closest('ewt-install-dialog');

      if (
        topModalRef === modalRef &&
        !modalRef.current.contains(event.target) &&
        !isEwtInstallDialog
      ) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal, getTopModal]);

  return (
    <div className="PopupUI Popup">
      <div className={`PopupUI-Block ${className}`} ref={modalRef}>
        <button
          id="closeModalButton"
          className="bg-close absolute right-2 top-2 h-[35px] w-[35px] bg-stone-200 hover:bg-stone-300"
          onClick={closeModal}
        ></button>
        {children}
      </div>
    </div>
  );
}

export default ModalLayout;
